<template>
  <auth-layout>
    <div class="auth-contents">
      <a-card :bordered="false" layout="vertical">
        <sd-heading as="h3">
          {{$t('views.login.reapply')}}
        </sd-heading>
        <app-form
          ref="loginForm"
          :fields="fields"
          :hideRequiredMark="true"
          :loading="loading"
          :submitButton="submitButton"
          :hasAlertList="true"
          @submitted="handleSubmit"
        >
          <template v-slot:alertList>
            <sd-alerts v-if="!$_.isEmpty(error)" message="" :description="error" :closable="false" type="error"/>
          </template>
        </app-form>
        <p class="auth-notice mt-30">
          {{$t('views.login.wantToSignInAs') + ' ' + currentRole.toLowerCase()}}?
          <router-link :to="`/${currentRole.toLowerCase()}/auth/`">
            {{$t('actions.clickHere')}}
          </router-link>
        </p>
      </a-card>
    </div>
  </auth-layout>
</template>
<script>
import {ref, reactive} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {AuthLayout} from './style';

export default {
  name: 'Login',
  components: {
    AuthLayout
  },
  setup() {
    const {dispatch} = useStore();
    const route = useRoute();

    const loginForm = ref(null);
    const error = ref('');
    const currentRole = ref(route.meta.key);

    const fields = {
      email: {
        type: 'text',
        class: 'mb-15',
        size: 'large',
        label: 'models.user.attributes.email',
        fieldValue: '',
        span: 24
      },
      password: {
        type: 'password',
        class: 'mb-15',
        label: 'models.user.attributes.password',
        fieldValue: '',
        size: 'large',
        span: 24
      }
    };
    const submitButton = reactive({
      label: 'actions.reapply',
      class: 'btn-signin',
      size: 'large',
      type: 'primary',
      block: false
    });
    const loading = ref(false);
    const handleSubmit = (data) => {
      loading.value= true;
      dispatch('reApply', data).then((response) => {
        error.value = response.errors ? response.errors[0] : '';
        loading.value = false;
      });
    };

    return {
      loginForm,
      fields,
      submitButton,
      loading,
      error,
      handleSubmit,
      currentRole
    };
  }
};
</script>
